@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
body {
  margin: 0;
  font-family: 'Noto Sans KR';
  font-style: normal;
}
